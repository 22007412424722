<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="appData === undefined">
      <h4 class="alert-heading">Error fetching {{ modulename }} details</h4>
      <div class="alert-body">
        <b-link class="alert-link" :to="{ name: GENAPP_APP_STORE_MODULE_NAME }">
          No {{ modulename }} details found
        </b-link>
      </div>
    </b-alert>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
       <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                    >
    <b-row v-if="appData" class="invoice-preview">
      <!-- {{ vacinatedval }} -->
      <b-col cols="12" sm="12" md="12" class="mt-1">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="d-print-block d-none invoice-padding pb-0">
            <div
              class="d-flex justify-content- flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <logo style="height: 10vw; wipricing: 10vw" />
                  <h3 class="text-primary invoice-logo">FRP</h3>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-card-body class="invoice-padding pb-0">
              <h5>{{ $t("fields.childrendetails") }}</h5>

              <div class="row">
                <div class="col-sm-12 col-md-4 mt-2">
                  <h5>{{ $t("fields.childrenname") }}</h5>
                  <h6>
                    <b> {{ famData.data.name }}</b>
                  </h6>
                </div>
                <div class="col-sm-12 col-md-4 mt-2">
                  <h5>{{ $t("fields.dateofbirth") }}</h5>
                  <h6>
                    <b>{{ famData.data.data.dob }} </b>
                  </h6>
                </div>
                <div class="col-sm-12 col-md-4 mt-2">
                  <h5>{{ $t("fields.relation") }}</h5>
                  <h6>
                    <b>{{ famData.data.data.finalrelation }}</b>
                  </h6>
                </div>
                <div class="col-sm-12 col-md-4 mt-2">
                  <h5>{{ $t("fields.bloodgrouptype") }}</h5>
                  <h6>
                    <b>{{ famData.data.data.bloodgroup }} </b>
                  </h6>
                </div>
                <div class="col-sm-12 col-md-4 mt-2">
                  <h5>{{ $t("fields.allergies") }}</h5>
                  <h6>
                    <b>{{ famData.data.data.allergies || "" }} </b>
                  </h6>
                </div>
              </div>
              <hr />
              <h5>{{ modulename }} {{ $t("fields.details") }}</h5>

            <div
              class="table-responsive"
              style="overflow: scroll; height: 70vh"
            >
              <table class="mt-2 table mt-xl-0 w-100 table-bordered">
                <thead>
                  <tr>
                    <th style="vertical-align: middle; width: 20%">
                      {{ $t("fields.vaccinename") }}
                    </th>
                    <th style="width: 15%">{{ $t("fields.dossage") }}</th>
                    <th style="width: 15%">
                      {{ $t("fields.vaccinatedduedate") }}
                    </th>
                    <th style="width: 15%">{{ $t("fields.vacinateddate") }}</th>
                    <!-- <th style="width: 20%">{{ $t("fields.height") }}</th> -->

                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in appData"
                    :key="'vaccination' + index"
                  >
                    <th class="pb-50">
                      <span>
                        {{ item.data.vaccinename }}
                      </span>
                    </th>
                    <td class="pb-50">
                      <span>
                        {{ item.data.dossage }}
                      </span>
                    </td>
                    <td class="pb-50">
                      <span>
                        {{ item.data.vaccinatedduedate }}
                      </span>
                    </td>
                    <td class="pb-50">
                      <flat-pickr
                        v-model="vacinatedval.vacinateddate[index]"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                        placeholder="DD-MM-YYYY"
                        class="form-control"
                      />
                    </td>
                    <!-- <td>
 <inputtext
                      name="height"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.height')
                      "
                      :placeholder="
                       $t('fields.height')
                      "
                      :valuex="appData.height"
                      @updatedata="(val) => (appData.height = val)"
                      validations=""
                    ></inputtext>
                    </td> -->

                  </tr>
                </tbody>
              </table>
            </div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1 float-right"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
          </b-card-body>
          <hr class="invoice-spacing" />
        </b-card>
      </b-col>
      <!-- <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <viewsidelist
          :modulefield="GENAPP_APP_STORE_MODULE_NAME"
          :printview="printview"
          :id="appData.id"
          :checkdeletefun="checkdeletefun"
        ></viewsidelist>
      </b-col> -->
    </b-row>
              </b-form>
            </validation-observer>
  </section>
</template>

<script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BAvatar,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardText,
  BCard,
  BTable,
  BTableLite,
  BForm,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import DocumentList from "@/views/Component/DocumetsList/DocumentList.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import inputtext from "@/views/Component/Input/inputtext.vue";

import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BCard,
    BAvatar,
    viewsidelist,
    BRow,
  BForm,
    BCardBody,
    BCardText,
    inputtext,
    flatPickr,
    BTable,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    DocumentList,
    BLink,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "children-vaccination";
    const modulename = "Vaccination";
    const modulenamesub = "vaccination";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    let storetype = "addData";

    const appData = ref({
      id: [],
      value: [],
    });

    let famData = ref(null);
    const serviceprov = ref(null);
    const vacinatedval = ref({
      id: [],
      vacinateddate: [],
    });
    const printview = () => {
      window.print();
    };
    var tempcatch = 1;
     const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/editData",
            vacinatedval
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    " editted successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          }).then((res) => {
            tempcatch=1
          })
          .catch((error) => {
            console.log('error',error)
            tempcatch = 1;
            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const { deletefun } = useList();
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/familymemberData", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        famData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        // router
        //   .push({
        //     name: GENAPP_APP_STORE_MODULE_NAME,
        //   })
        //   .catch(() => {});
      });
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        appData.value = response.data.data.data;
        for (var i = 0; i < response.data.data.data.length; i++) {
          vacinatedval.value.id.push(response.data.data.data[i].id);
          vacinatedval.value.vacinateddate.push(
            response.data.data.data[i].data.vaccinateddate
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        // router
        //   .push({
        //     name: GENAPP_APP_STORE_MODULE_NAME,
        //   })
        //   .catch(() => {});
      });

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      onSubmit,
      printview,
      vacinatedval,
      serviceprov,
      famData,
    };
  },
  mounted() {

  },
  methods: {
    checkdeletefun(val) {
      var self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          self.$router.push({
            name: this.GENAPP_APP_STORE_MODULE_NAME,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }
  .d-print-block {
    display: block !important;
  }
  .content-header-right {
    display: none;
  }
  .invoice-actions {
    display: none;
  }
  .breadcrumbs-top {
    display: none;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  } // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-wipricing: 50% !important;
            }
            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
